export default [
		{
				path: '/projects',
				name: 'projects',
				component: () => import('@/views/projects/Projects.vue'),
        meta: {
						resource: 'Auth',
						isProject: true,
				},
		},
		{
				path: '/project/:project/backlog',
				name: 'projects-backlog',
				component: () => import('@/views/backlog/Backlog.vue'),
				meta: {
						pageTitle: 'Backlog',
						breadcrumb: [
								{
										text: 'backlog',
										active: true,
								},
						],
						resource: 'Auth',
						isProject: true,
				},
		},
		{
				path: '/project/:project/board',
				name: 'board',
				component: () => import('@/views/kanban/Board.vue'),
				meta: {
						breadcrumb: false,
						resource: 'Auth',
						isProject: true,
				},
		},
		{
				path: '/:project-:task',
				name: 'projects-task',
				component: () => import('@/views/task/TaskSingle.vue'),
				meta: {
						breadcrumb: false,
						resource: 'Auth',
						isProject: true,
				},
		},
		{
				path: '/project/:project/releases',
				name: 'releases',
				component: () => import('@/views/release/Release.vue'),
				meta: {
						pageTitle: 'Release',
						breadcrumb: [
								{
										text: 'releases',
										active: true,
								},
						],
						// resource: 'Auth',
						resource: 'doc_schema_release',
						isProject: true,
				},
        props: route => ({ searchName: route.query.search })
		},
		{
				path: '/project/:project/releases/:release',
				name: 'release',
				component: () => import('@/views/release/ReleaseForm.vue'),
				meta: {
						pageTitle: 'Release',
						breadcrumb: [
								{
										text: 'releases > release',
										active: true,
								},
						],
						resource: 'doc_schema_release',
						isProject: true,
				},
		},
		{
				path: '/project/:project/releases/:release/doc',
				name: 'doc',
				component: () => import('@/views/release/ReleaseDoc.vue'),
				meta: {
						pageTitle: 'Release',
						breadcrumb: [
								{
										text: 'releases > release > doc',
										active: true,
								},
						],
						resource: 'Auth',
						isProject: true,
				},
		},/*{
				path: '/project/:project/task/:task',
				name: 'projects-task',
				component: () => import('@/views/task/TaskSingle.vue'),
				meta: {
						breadcrumb: false,
						resource: 'Auth',
						isProject: true,
				},
		},*/
];
