export default {
  namespaced: true,
  state: {
    userData: JSON.parse(localStorage.getItem('userData')) || null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.userData = userData;
    },
  },
  getters: {
    //
  },
  actions: {
    updateUserData({ commit }, userData) {
      commit('SET_USER_DATA', userData);
    },
  },
};
