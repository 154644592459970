export default {
		apiUrl: (process.env.VUE_APP_API_BASE_URL) ? process.env.VUE_APP_API_BASE_URL : 'https://api.lk.yakovlev.studio/rest/',
		version: 1,
		
		// Endpoints
		loginEndpoint: '/account/auth',
		refreshEndpoint: '/account/refresh',
		settingsEndpoint: '/settings/config',
		projectEndpoint: '/projects',
		createTask: '/tasks',
		
		// This will be prefixed in authorization header with token
		// e.g. Authorization: Bearer <token>
		tokenType: 'Bearer',
		
		// Value of this property will be used as key to store JWT token in storage
		storageTokenKeyName: 'accessToken',
		storageRefreshTokenKeyName: 'refreshToken',
}
// https://dev-api.lk.yakovlev.studio/rest/
// https://api.lk.yakovlev.studio/rest/
