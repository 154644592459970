import Http from "@/api/http";

export default class HttpInit extends Http {
		config() {
				return this.axiosIns.get(this.apiConfig.settingsEndpoint)
		}
		projects() {
				return this.axiosIns.get(this.apiConfig.projectEndpoint)
		}
}
