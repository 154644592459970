export default [
		{
				path: '/board',
				name: 'board-tmp',
				component: () => import('@/views/kanban/Board.vue'),
				meta: {
						breadcrumb: [
								{
										text: 'Projects',
										active: false,
								},
								{
										text: 'Project Name',
										active: false,
								},
								{
										text: 'Board Name',
										active: true,
								},
						],
						resource: 'Auth',
						isProject: true,
				},
		},
];
