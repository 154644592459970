import Vue from "vue";
import moment from "moment";
import "moment/locale/ru";

Vue.filter("formatDate", (value) => moment(value).format("DD MMM YYYY HH:mm:ss"));

Vue.filter("formatDateWithoutYear", (value) => moment(value).format("DD MMM HH:mm:ss"));

Vue.filter("formatDateOnly", (value) => value ? moment(value).format("DD MMM YYYY") : 'Не задано');

Vue.filter("formatDateDefault", (value) => value ? moment(value).format("DD.MM.YYYY") : 'Не задано');

Vue.filter("formatDateDefaultWithTime", (value) => value ? moment(value).format("DD.MM.YYYY HH:mm") : 'Не задано');
