import { initApi } from '@/api';

export default {
  namespaced: true,
  state: {
    projects: JSON.parse(localStorage.getItem('projects')) || [],
  },
  mutations: {
    SET_PROJECTS(state, projects) {
      state.projects = projects;
    },
  },
  getters: {
    //
  },
  actions: {
    getProjects({ commit, state }) {
      return initApi.projects().then((res) => {
        commit('SET_PROJECTS', res.data.projects);
        localStorage.setItem('projects', JSON.stringify(res.data.projects));
      });
    },

    updateProjects({ commit }, projects) {
      commit('SET_PROJECTS', projects);
    },
  },
};
