import { projectApi, docsApi } from '@/api';

const epicName = 'epic';
const taskName = 'task';

export default {
  namespaced: true,
  state: {
    backlogTasks: null,
    currentProject: null,
    sprints: null,
    openSprint: null,
    board: null,
    activeSprintStatus: null,
    activeTask: null,
    activeEpic: null,
    taskTypes: null,
    taskPriorityList: null,
    epics: null,
    isPending: false,
    colorStatus: [],
    releases: {
      total: 0,
      list: [
        /* {
          id: 1,
          version: '3.1.0',
          started_at: '2022-05-11 12:16:49',
          deadline: {
            planned_at: '2022-03-11 12:16:49',
            finished_at: '2023-07-01 12:16:49',
          },
          qa: {
            planned_at: '2022-06-11 12:16:49',
            finished_at: '2023-08-11 12:16:49',
          },
          rc: {
            planned_at: '2023-11-11 12:16:49',
            finished_at: '2023-09-11 12:16:49',
          },
          deploy: {
            planned_at: '2023-11-15 12:16:49',
            finished_at: '2023-09-15 12:16:49',
          },
          status: {name: 'new'},
          epics: [
            // 'HM30-5', 'HM30-8'

            {
              id: 1,
              key: 'HM30-24',
              number: 1,
              name: '1C x BACK',
              icon: 'string',
            },
            {
              id: 2,
              key: 'HM30-5',
              number: 2,
              name: 'Внедрение процесса автоматизированного тестирования.',
              icon: 'string',
            },
            {
              id: 3,
              key: 'HM30-23',
              number: 3,
              name: 'Архитектура BACK',
              icon: 'string',
            },
          ],
          tasks: [
            {
              id: 2,
              key: 'HM30-1',
              number: 2,
              name: 'Инициализация XM v.3',
              icon: 'string',
              epic: {
                name: 'Мобильное приложение 3.0',
              },
            },
            {
              id: 3,
              key: 'HM30-2',
              number: 3,
              name: 'Реализация push messaging 12.0',
              icon: 'string',
              epic: {
                name: 'Мобильное приложение 3.0',
              },
            },
          ],
          docPO: '<p>какой-нибудь текст</p>',
          docReport: '<p>тестовый текст</p>',
        },
        {
          id: 2,
          version: '3.1.3',
          started_at: '2022-05-11 12:16:49',
          deadline: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          qa: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          rc: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          deploy: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          status: 'new',
          epics: [
            {
              id: 2,
              key: 'epic_2',
              number: 2,
              name: 'test_2',
              icon: 'string',
            },
            {
              id: 3,
              key: 'epic_3',
              number: 3,
              name: 'test_3',
              icon: 'string',
            },
          ],
          tasks: [],
          docPO: '<p>какой-нибудь текст для примера</p>',
          docReport: '<p>тестовый текст ...бла ...бла</p>',
        },
        {
          id: 3,
          version: '3.1.5',
          started_at: '2022-05-11 12:16:49',
          deadline: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          qa: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          rc: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          deploy: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          status: 'new',
          epics: [],
          tasks: [],
          docPO: '<p>какой-нибудь текст</p>',
          docReport: '<p>тестовый текст</p>',
        },
        {
          id: 4,
          version: '3.2.4',
          started_at: '2022-05-11 12:16:49',
          deadline: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          qa: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          rc: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          deploy: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          status: 'new',
          epics: [],
          tasks: [],
          docPO: '<p>какой-нибудь текст для примера</p>',
          docReport: '<p>тестовый текст ...бла ...бла</p>',
        },
        {
          id: 5,
          version: '3.2.5',
          started_at: '2022-05-11 12:16:49',
          deadline: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          qa: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          rc: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          deploy: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          status: 'new',
          epics: null,
          tasks: null,
          docPO: '<p>какой-нибудь текст</p>',
          docReport: '<p>тестовый текст</p>',
        },
        {
          id: 6,
          version: '3.3.4',
          started_at: '2022-05-11 12:16:49',
          deadline: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          qa: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          rc: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          deploy: {
            planned_at: '2022-05-11 12:16:49',
            finished_at: '2022-05-11 12:16:49',
          },
          status: 'new',
          epics: [],
          tasks: [],
          docPO: '<p>какой-нибудь текст для примера</p>',
          docReport: '<p>тестовый текст ...бла ...бла</p>',
        }, */
      ],
    },
  },
  mutations: {
    SET_BACKLOG_TASKS(state, tasks) {
      state.backlogTasks = tasks;
    },
    SET_CURRENT_PROJECT(state, payload) {
      state.currentProject = payload;
    },
    SET_SPRINTS(state, payload) {
      state.sprints = payload;
    },
    SET_OPEN_SPRINT(state, payload) {
      state.openSprint = payload;
    },
    SET_BOARD(state, payload) {
      state.board = payload;
    },
    SET_ACTIVE_SPRINT_STATUS(state, payload) {
      state.activeSprintStatus = payload;
    },
    SET_ACTIVE_TASK(state, payload) {
      state.activeTask = payload;
    },
    UPDATE_ATTACHMENTS_ACTIVE_TASK(state, payload) {
      state.activeTask = {...state.activeTask, attachments: [ ...state.activeTask.attachments , payload]};
    },
    SET_TASK_TYPES(state, payload) {
      state.taskTypes = payload;
    },
    SET_PRIORITY_LIST(state, payload) {
      state.taskPriorityList = payload;
    },
    SET_ACTIVE_EPIC(state, payload) {
      state.activeEpic = payload;
    },
    SET_EPICS(state, payload) {
      state.epics = payload;
    },
    SET_PENDING(state, payload) {
      state.isPending = payload;
    },
    SET_COLOR_STATUS(state, payload) {
      state.colorStatus = payload;
    },
    SET_RELEASES(state, payload) {
      state.releases = payload;
    },
  },
  getters: {
    sortSprints(state) {
      return state.sprints.sort((a, b) => (a.started_at ? -1 : 1));
    },
    startedSprint(state) {
      return state.sprints.filter((item) => item.started_at.length)[0];
    },
    activeSprint(state) {
      return state.sprints.filter((item) => item.status_id == state.activeSprintStatus)[0];
    },
    issueActiveSprint(state, getters) {
      return !!getters.activeSprint || !!getters.startedSprint;
    },
    statusEpicId(state) {
      const type = state.taskTypes.filter((item) => item.name == epicName);
      return type.length ? type[0].id : null;
    },
    statusTaskId(state) {
      const type = state.taskTypes.filter((item) => item.name == taskName);
      return type.length ? type[0].id : null;
    },
    defaultPriority(state) {
      // дефолтным считаем серединный приоритет
      const index = Math.round(state.taskPriorityList.length / 2);
      return state.taskPriorityList[index - 1];
    },
    getReleaseById(state) {
      return (id) => state.releases.list.find((release) => release.id === Number(id));
    },
  },
  actions: {
    getBacklogTasks({ commit, state }) {
      return projectApi.getBacklog(state.currentProject).then((res) => {
        commit('SET_BACKLOG_TASKS', res.data.tasks);
      });
    },

    getBoard({ commit, state }) {
      return projectApi.getBoard(state.currentProject).then((res) => {
        commit('SET_BOARD', res.data);
      });
    },

    getSprints({ commit, state }, activeSprintStatus = null) {
      return projectApi.getSprints(state.currentProject).then((res) => {
        commit('SET_SPRINTS', res.data.sprints);
      });
    },

    getActiveSprintStatus({ commit, state }) {
      projectApi.getDictionaries(state.currentProject).then((res) => {
        const active = res.data.project_dictionaries.sprint_statuses.filter((item) => item.active);
        const status = active[0] ? active[0].id : null;
        commit('SET_ACTIVE_SPRINT_STATUS', status);
        commit('SET_TASK_TYPES', res.data.project_dictionaries.task_types);
        commit('SET_PRIORITY_LIST', res.data.project_dictionaries.priorities);
        commit('SET_COLOR_STATUS', res.data.project_dictionaries.workflow_steps);
      });
    },

    getEpics({ commit, state }) {
      return projectApi.getEpics(state.currentProject).then((res) => {
        commit('SET_EPICS', res.data.backlog_epics);
      });
    },

    getReleases({ commit, state }, params) {
      commit('SET_PENDING', true);
      return projectApi
        .getReleases(state.currentProject, params)
        .then((res) => {
          const { headers, data } = res;
          // console.log('res:', res.headers);
          const total = Number(headers['x-pagination-total']);

          const resList = data.releases;
          const list = resList.map((item) => {
            const { id, properties } = item;
            return {
              id,
              ...properties,
              ...(properties.epics && {
                epics: properties.epics.map((epic) => ({
                  key: epic.task_key,
                  name: epic.task_name,
                  // tasks: epic.child_tasks.map((item) => item.task_key),
                })),
              }),
              ...(properties.tasks && {
                tasks: properties.tasks.map((task) => ({
                  key: task.task_key,
                  name: task.task_name,
                  //TODO
                  labels: task?.label_name ? [{name: task.label_name}] : [],
                  ...(task.epic_name && { epic: { name: task.epic_name, key: task.epic_key } }),
                })),
              }),
            };
          });
          // console.log('list:', list);
          commit('SET_RELEASES', { list, total });
          commit('SET_PENDING', false);
        })
        .catch(() => commit('SET_PENDING', false));
    },

    getRelease({ commit, state }, { id, callback }) {
      commit('SET_PENDING', true);
      return docsApi
        .getRelease(id)
        .then((res) => {
          const { data } = res;

          const { id, properties } = data.doc;
          const release = {
            id,
            ...properties,
            ...(properties.epics && {
              epics: properties.epics.map((epic) => ({
                key: epic.task_key,
                name: epic.task_name,
                // tasks: epic.child_tasks.map((item) => item.task_key),
              })),
            }),
            ...(properties.tasks && {
              tasks: properties.tasks.map((task) => ({
                key: task.task_key,
                name: task.task_name,
                ...(task.epic_name && { epic: { name: task.epic_name, key: task.epic_key } }),
              })),
            }),
          };
          callback && callback(release);
          // console.log('list:', list);

          // commit('SET_RELEASES', { release, total });
          commit('SET_PENDING', false);
        })
        .catch(() => commit('SET_PENDING', false));
    },

    updateActiveTask({ commit }, task) {
      commit('SET_ACTIVE_TASK', task);
    },
    
    updateAttachmetsActiveTask({ commit }, attachment) {
      commit('UPDATE_ATTACHMENTS_ACTIVE_TASK', attachment);
    },

    updateCurrentProject({ commit }, project) {
      commit('SET_CURRENT_PROJECT', project);
    },

    updateOpenSprint({ commit }, sprint) {
      commit('SET_OPEN_SPRINT', sprint);
    },

    updateActiveEpic({ commit }, list) {
      commit('SET_ACTIVE_EPIC', list);
    },

    updatePending({ commit }, payload) {
      commit('SET_PENDING', payload);
    },
  },
};
