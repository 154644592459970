export default {
		namespaced: true,
		state: {
				modalTaskType: 1,
		},
		getters: {
				//
		},
		mutations: {
				UPDATE_MODAL_TASK_TYPE(state, val) {
						state.modalTaskType = val;
				},
		},
		actions: {},
}
