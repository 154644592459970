import apiConfig from './apiConfig'
import axios from "axios";
//import Vue from "vue/types/vue";
import { getErrors } from "@/helpers/errors";
import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)

export default class Http {
		
		axiosIns = axios;
		
		apiConfig = { ...apiConfig }
		
		isAlreadyFetchingAccessToken = false;
		
		constructor(apiOverrideConfig) {
				this.apiConfig = { ...this.apiConfig, ...apiOverrideConfig }
				
				// Request Interceptor
				this.axiosIns.interceptors.request.use(
						config => {
								// Base Url
								config.baseURL = this.apiConfig.apiUrl;
								
								// Add version
								config.headers.version = this.apiConfig.version;
								
								// config.headers.withCredentials = true;
								
								// Get token from localStorage
								const accessToken = this.getToken();
								
								// If token is present add it to request's Authorization Header
								if (accessToken) {
										// eslint-disable-next-line no-param-reassign
										config.headers.Authorization = `${this.apiConfig.tokenType} ${accessToken}`
								}
							return config
						},
						error => Promise.reject(error),
				)
				
				// Add request/response interceptor
				this.axiosIns.interceptors.response.use(
						response => response,
						error => {
								const { response } = error;
								const errorStatusList = [401, 419];
								
								if (response && errorStatusList.includes(response?.status)) {
										this.refreshToken(error);
								}
								
								if (response?.status === 404) {
										// display error 404 page
								}
								
								return Promise.reject(error);
						},
				)
		}
		clearStorage() {
				localStorage.removeItem(this.apiConfig.storageTokenKeyName);
				localStorage.removeItem(this.apiConfig.storageRefreshTokenKeyName);
				
				// Remove userData from localStorage
				localStorage.removeItem('userData');
				
				// Remove projects from localStorage
				localStorage.removeItem('projects');
		}
		getToken() {
				return localStorage.getItem(this.apiConfig.storageTokenKeyName)
		}
		
		getRefreshToken() {
				return localStorage.getItem(this.apiConfig.storageRefreshTokenKeyName)
		}
		
		refreshToken(error) {
				const { config } = error;
				const originalRequest = config;
				if(!this.isAlreadyFetchingAccessToken) {
						this.isAlreadyFetchingAccessToken = true;
						const refresh = this.getRefreshToken();
						
						if(refresh) {
								this.axiosIns.post(this.apiConfig.refreshEndpoint, { refresh}).then((res) => {
										localStorage.setItem(this.apiConfig.storageTokenKeyName, res.data.tokens.access);
										localStorage.setItem(this.apiConfig.storageRefreshTokenKeyName, res.data.tokens.refresh);
										window.location.reload();
										//this.axiosIns(originalRequest);
								}).catch(() => {
										this.clearStorage();
										window.location.href = "/";
								})
						} else {
								this.clearStorage();
								window.location.href = "/";
						}
				}
		}
		
		//-------------------------------------------------
		// TODO: дописать обертку для вызовов АПИ
		sendRequest(request, successCallback = null, errorCallback = null) {
				request().then((res) => {
						this.makeSuccessToast();
						if(successCallback) successCallback(res);
				}).catch((error) => {
						if(error.response) {
								const errors = getErrors(error.response.data.errors);
								if(errors.general) {
										this.makeErrorToast(errors.general[0].status, errors.general[0].message);
								}
						}
						if(errorCallback) errorCallback(error);
				});
		}
		makeSuccessToast() {
				this.$bvToast.toast("Данные успешно обновлены", {
						title: this.$t("Data Update"),
						variant: "success",
						solid: true,
				});
		}
		makeErrorToast(status, message) {
				Vue.$bvToast.toast(`Ошибка загрузки данных. ERROR: ${status} ${message}`, {
						title: this.$t("Сreating a task"),
						variant: "danger",
						solid: true,
				});
		}
		//------------------------------------------------------------------------
		
}
