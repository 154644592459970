import Vue from 'vue';
import Vuex from 'vuex';

// Modules
// import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import studio from './studio';
import user from './user';
import project from './project';
import projects from './projects';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    // 'app-ecommerce': ecommerceStoreModule,
    studio,
    user,
    project,
    projects,
  },
  strict: process.env.DEV,
});
