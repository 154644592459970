import Vue from 'vue';
import VueRouter from 'vue-router';

// Routes
import { canNotNavigate } from '@/libs/acl/routeProtection';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/api/utils';
import store from '@/store';
import board from './routes/board';
import project from './routes/project';
import page from './routes/page';
import user from './routes/user';
import ability from '../libs/acl/ability';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', redirect: { name: 'projects' } },
    ...board,
    ...project,
    ...page,
    ...user,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();

  if (!isLoggedIn && to.name !== 'auth-login') {
    return next({ name: 'auth-login' });
  }
  if (canNotNavigate(to)) {
    // Redirect to login if not logged in
    // if (!isLoggedIn) return next({ name: 'auth-login' })
    // If logged in => not authorized
    // return next({ name: 'misc-not-authorized' })
    // console.log('нет доступа!!', to);
    return next('error-404');
  }
  // console.log('to', to);
    
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }
  const project = to.params.hasOwnProperty('project') ? to.params.project : null;
  store.dispatch('project/updateCurrentProject', project);

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
