export default [
		{
				path: '/error-404',
				name: 'error-404',
				component: () => import('@/views/error/Error404.vue'),
				meta: {
						layout: 'full',
						resource: 'Auth',
						action: 'read',
				},
		},
		{
				path: '/login',
				name: 'auth-login',
				component: () => import('@/views/Login.vue'),
				meta: {
						layout: 'full',
						resource: 'Auth',
						redirectIfLoggedIn: true,
				},
		},
];
