export function getErrors(errors) {
		let responseErrors = {};
		
		errors.forEach((item) => {
				if(item.field.length > 0) {
						if(!responseErrors.hasOwnProperty(item.field)) responseErrors[item.field] = [];
						responseErrors[item.field].push(item);
				} else {
						if(!responseErrors.hasOwnProperty("general")) responseErrors["general"] = [];
						responseErrors["general"].push(item);
				}
		});
		
		return responseErrors;
}
