import HttpAuth from "@/api/httpAuth";
import HttpInit from "@/api/httpInit";
import HttpTask from "@/api/httpTask";
import HttpProject from "@/api/httpProject";
import HttpDocs from "@/api/httpDocs";

export const initApi = new HttpInit({});

export const authApi = new HttpAuth({});

export const taskApi = new HttpTask({});

export const projectApi = new HttpProject({});

export const docsApi = new HttpDocs({});
