export default [
		{
				path: '/user',
				name: 'user',
				component: () => import('@/views/user/User.vue'),
				meta: {
						breadcrumb: false,
						resource: 'Auth',
				},
		},
];
