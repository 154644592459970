import Http from '@/api/http';
import { getParams } from '../helpers/index';

export default class HttpProject extends Http {
  getBacklog(project_key) {
    return this.axiosIns.get(`/projects/${project_key}/backlog`);
  }

  getConfig(project_key) {
    return this.axiosIns.get(`/projects/${project_key}/config`);
  }

  getBoard(project_key) {
    return this.axiosIns.get(`/projects/${project_key}/board`);
  }

  getDictionaries(project_key) {
    return this.axiosIns.get(`/projects/${project_key}/dictionaries`);
  }

  getEpics(project_key) {
    return this.axiosIns.get(`/projects/${project_key}/epics`);
  }

  // Sprints
  getSprints(project_key) {
    return this.axiosIns.get(`/projects/${project_key}/sprints`);
  }

  addSprint(project_key, data) {
    return this.axiosIns.post(`/projects/${project_key}/sprints`, data);
  }

  editSprint(uuid, data) {
    return this.axiosIns.patch(`/projects/sprints/${uuid}`, data);
  }

  stopSprint(project_key, data) {
    return this.axiosIns.post(`/projects/${project_key}/sprints/finish`, data);
  }

  deleteSprint(uuid) {
    return this.axiosIns.delete(`/projects/sprints/${uuid}`);
  }

  // get for new project
  getAllUsers() {
    return this.axiosIns.get('/projects/users');
  }

  getWorkflowSteps() {
    return this.axiosIns.get('/projects/workflow/steps');
  }

  // create Project
  create(data) {
    return this.axiosIns.post('/projects', data);
  }

  // get Releases
  getReleases(project_key, params) {
    const page = params?.page;
    const search = params?.search;
    const config = {
      ...(search && { params: { search } }),
      headers: {
        ...(page && { 'x-pagination-page': page }),
        'x-pagination-size': 5,
      },
    };
    return this.axiosIns.get(`/projects/${project_key}/releases`, config);
  }
}
