import Http from '@/api/http';
import qs from 'qs';

export default class HttpTask extends Http {
  // Task
  create(...args) {
    return this.axiosIns.post(this.apiConfig.createTask, ...args);
  }

  task(project_key, task_number) {
    return this.axiosIns.get(`/tasks/${project_key}/${task_number}`);
  }

  getTasks(project_key, params) {
    const task_types = params?.types;
    const search = params?.search;
    const page = params?.page;
    const config = {
      headers: {
        ...(page && { 'x-pagination-page': page }),
        'x-pagination-size': 8,
      },
      params: {
        ...(search && { search }),
        context: { task_types, project_key },
      },
      paramsSerializer: (params) => {
        // console.log('qs.str:', qs.stringify(params));
        return qs.stringify(params, { encode: false });
      },
    };

    return this.axiosIns.get('/tasks', config);
  }

  getChildrenTask(project_key, task_number) {
    return this.axiosIns.get(`/tasks/${project_key}/${task_number}/children`);
  }

  updateTask(project_key, task_number, data) {
    return this.axiosIns.patch(`/tasks/${project_key}/${task_number}`, data);
  }

  deleteTask(project_key, task_number) {
    return this.axiosIns.delete(`/tasks/${project_key}/${task_number}`);
  }

  // History
  getHistory(project_key, task_number) {
    return this.axiosIns.get(`/tasks/${project_key}/${task_number}/history`);
  }

  // Comments
  getComments(project_key, task_number, sort = null) {
    const params = sort ? `sort[${Object.keys(sort)[0]}]=${Object.values(sort)[0]}` : '';
    return this.axiosIns.get(`/tasks/${project_key}/${task_number}/comments?${params}`);
  }

  addComment(project_key, task_number, data) {
    return this.axiosIns.post(`/tasks/${project_key}/${task_number}/comments`, data);
  }

  editComment(uuid, data) {
    return this.axiosIns.patch(`/tasks/comments/${uuid}`, data);
  }

  deleteComment(uuid) {
    return this.axiosIns.delete(`/tasks/comments/${uuid}`);
  }

  // Timer
  timerStart(project_key, task_number) {
    return this.axiosIns.post('/timer/start', {
      project_key,
      task_number,
    });
  }

  timerFinish(project_key, task_number) {
    return this.axiosIns.post('/timer/finish', {
      project_key,
      task_number,
    });
  }

  timerCancel(project_key, task_number) {
    return this.axiosIns.post('/timer/cancel', {
      project_key,
      task_number,
    });
  }

  timerComment(uuid, comment) {
    return this.axiosIns.patch(`/timer/track/${uuid}`, { comment });
  }

  getTimeTracker(project_key, task_number) {
    return this.axiosIns.get(`/tasks/${project_key}/${task_number}/timer`);
  }

  // upload file
  uploadFile(project_key, task_number, formdata) {
    return this.axiosIns.post(`/tasks/${project_key}/${task_number}/attachment`, formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}
