import { initApi } from '@/api';
import store from '@/store';

const colorListTask = {
  task: 'text-primary',
  history: 'text-success',
  bug: 'text-danger',
  epic: 'text-warning',
};

const iconListTask = {
  task: 'CheckSquareIcon',
  history: 'BookmarkIcon',
  bag: 'AlertCircleIcon',
  epic: 'GridIcon',
};

const dict = {
  m: 60,
  h: 3600,
  d: 28800,
  w: 144000,
};

const dictHour = {
  d: 8,
  w: 40,
};

export function init() {
  const getConfig = initApi.config();
  const getProject = initApi.projects();

  return Promise.all([getConfig, getProject]).then((results) => {
    // console.log('results:', results);
    const user = results[0];
    const projects = results[1];

    // Права пока оставляем по умолчанию из шаблона
    const initAbility = []; // [{ action: 'read', subject: 'all' }];

    // Устанавливаем права
    const permissions = user.data.config?.permissions.map(({ available, context }) => ({
      action: available,
      subject: context,
    }));

    const ability = permissions?.length ? permissions : initAbility;

    const userData = { ...user.data.config.user, ability };

    // Устанавливаем данные пользователя
    store.dispatch('user/updateUserData', userData);

    // Устанавливаем данные пользователя
    store.dispatch('projects/updateProjects', projects.data.projects);

    localStorage.setItem('userData', JSON.stringify(userData));
    // Устанавливаем список проектов
    localStorage.setItem('projects', JSON.stringify(projects.data.projects));
    
    return ability;
  });
}

export function colorTask(type) {
  return colorListTask[type] || 'text-secondary';
}

export function iconTask(type) {
  return iconListTask[type] || 'CheckSquareIcon';
}
/*
export function colorTaskStatus(status) {
		return statusColorList[status] || 'secondary';
} */

/* Время в сек. приводит к формату {n}d {n}h {n}m */
export function toFormatFromSec(seconds) {
  if (seconds == 0 || seconds < 60) return `${seconds}s`;
  let dayCount = 0;
  let hourCount = 0;
  let minuteCount = 0;
  let out = '';

  const dayAll = seconds / dict.d;
  if (dayAll > 1) {
    dayCount = Math.trunc(dayAll);
    const restTime = seconds % dict.d;
    const hourAll = restTime / dict.h;
    if (hourAll > 1) {
      hourCount = Math.trunc(hourAll);
      minuteCount = Math.trunc(restTime % dict.h);
    } else {
      minuteCount = Math.trunc(restTime / dict.m);
    }
  } else {
    const hourAll = seconds / dict.h;
    if (hourAll > 1) {
      hourCount = Math.trunc(hourAll);
      minuteCount = Math.trunc((seconds % dict.h) / dict.m);
    } else {
      minuteCount = Math.trunc(seconds / dict.m);
    }
  }
  if (dayCount > 0) out += `${dayCount}d `;
  if (hourCount > 0) out += `${hourCount}h `;
  if (minuteCount > 0) out += `${minuteCount}m`;
  return out;
}

/* Время в сек. приводит к формату {n}w {n}d {n}h {n}m */
export function toFormatFromSec1(seconds, empty = true) {
  if (seconds == 0) return empty ? '' : '0s';
  if (seconds < 60) return `${seconds}s`;
  let weekCount = 0;
  let dayCount = 0;
  let hourCount = 0;
  let minuteCount = 0;

  let restTime = seconds;
  const weekAll = seconds / dict.w;

  if (weekAll >= 0) {
    weekCount = Math.trunc(weekAll);
    restTime -= weekCount * dict.w;
  }

  const dayAll = restTime / dict.d;
  if (dayAll >= 0) {
    dayCount = Math.trunc(dayAll);
    restTime -= dayCount * dict.d;
  }

  const hourAll = restTime / dict.h;
  if (hourAll >= 0) {
    hourCount = Math.trunc(hourAll);
    restTime -= hourCount * dict.h;
  }

  const minuteAll = restTime / dict.m;
  if (minuteAll >= 0) {
    minuteCount = Math.trunc(minuteAll);
  }
  const arr = [];
  if (weekCount > 0) arr.push(`${weekCount}w`);
  if (dayCount > 0) arr.push(`${dayCount}d`);
  if (hourCount > 0) arr.push(`${hourCount}h`);
  if (minuteCount > 0) arr.push(`${minuteCount}m`);

  return arr.join(' ');
}

/* Время в мин. приводит к формату {n}d {n}h {n}m */
export function toFormatFromMin(minutes) {
  return toFormatFromSec(minutes * 60);
}

/* Время в мин. приводит к формату {n}w {n}d {n}h {n}m */
export function toFormatFromMin1(minutes) {
  return toFormatFromSec1(minutes * 60);
}

/* Формат {n}d {n}h {n}m приводит в сек */
export function toSecondsFromFormat(format) {
  let out = 0;
  format.split(' ').forEach((item) => {
    const litera = item.substr(-1);
    if (dict.hasOwnProperty(litera)) {
      out += dict[litera] * parseInt(item);
    }
  });
  return out;
}

/**
 * Функция трансформации в GET параметры
 * @param params - объект параметров
 */
export const getParams = (params) =>
  `?${Object.entries(params)
    .map(([key, val]) => `${key}=${val}`)
    .join('&')}`;
