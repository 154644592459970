import Http from "@/api/http";

export default class HttpAuth extends Http {
		setToken(value) {
				localStorage.setItem(this.apiConfig.storageTokenKeyName, value)
		}
		setRefreshToken(value) {
				localStorage.setItem(this.apiConfig.storageRefreshTokenKeyName, value)
		}
		login(...args) {
				return this.axiosIns.post(this.apiConfig.loginEndpoint, ...args)
		}
		
}
