import Http from '@/api/http';

export default class HttpDocs extends Http {
  getSchema(project_key, doc = 'doc_schema_release') {
    const config = {
      headers: { 'x-project-key': project_key },
    };
    return this.axiosIns.get(`/docs/schema?doc=${doc}`, config);
  }

  // create Project
  addRelease(data, projectKey) {
    const config = {
      headers: { 'x-project-key': projectKey },
    };
    return this.axiosIns.post('/docs/releases', data, config);
  }

  // edit Project
  getRelease(id) {
    return this.axiosIns.get(`/docs/releases/${id}`);
  }

  // edit Project
  editRelease(data, project_key) {
    const config = {
      headers: { 'x-project-key': project_key },
    };
    return this.axiosIns.patch(`/docs/releases/${data.id}`, data, config);
  }

  // make Doc
  makeDoc(data, id) {
    return this.axiosIns.post(`/docs/releases/${id}/build`, data, { responseType: 'blob' });
  }
}
